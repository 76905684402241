.split-content {
	height: 80vh;
	display: flex;
	flex-direction: row;
	color: $font-dark-blue;

	.half-text {
		width: 50vw;
		background-color: $bg-light-green;
		display: flex;
		flex-direction: column;
		// align-items: center;
		height: 100%;
		justify-content: center;
		padding: 5%;
		font-size: 20px;

		.title {
			font-family: 'IBM Plex Serif';
			font-size: 8vmin;
			padding-bottom: 10%;
		}
	}

	.half-img {
		width: 50vw;

		.img-wrapper {
			height: 100%;

			img {
				min-width: 100%;
				height: 100%;
				width: auto;
				min-height: 100%;
				object-fit: cover;
			}
		}
	}

	@media screen and (max-width: $laptop) {
		.half-text {
			font-size: 18px;
		}
	}

	@media screen and (max-width: $tablet) {
		flex-direction: column;

		.half-text {
			width: 100%;
			height: 50%;
			padding: 35px;

			.title {
				padding-bottom: 3%;
			}
		}

		.half-img {
			width: 100%;
			height: 50%;
		}
	}

	@media screen and (max-width: $mobile) {
		.half-text {
			font-size: 14px;
			height: 60%;

			.title {
				padding-bottom: 10%;
			}
		}

		.half-img {
			height: 40%;
		}
	}
}
