$mobile-menu-width: 200px;

.nav-container {
	.navbar {
		width: 100vw;
		display: flex;
		justify-content: space-evenly;
		padding: 0 20%;
		height: 12vh;
		font-size: 1.2rem;
		z-index: 1;

		.nav-link {
			text-decoration: none;

			.au-nav {
				text-transform: uppercase;
				font-family: 'IBM Plex Serif';
				font-size: 1.8rem;
				letter-spacing: 0.2rem;
				white-space: nowrap;
			}
		}

		span {
			color: $font-dark-blue;
		}
	}

	.navbar-mobile {
		.navbar-head {
			height: 5rem;
			justify-content: flex-end;
			align-items: center;
			display: flex;
			font-family: 'IBM Plex Serif';
			font-size: 1rem;
			text-transform: uppercase;
			padding: 0 30px;

			@media screen and (max-width: $mobile) {
				font-size: 5vw;
			}
		}

		.background {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			width: $mobile-menu-width;
			background: $bg-green;
			height: 100vh;
		}

		.mobile-nav-menu {
			padding: 25px;
			position: fixed;
			top: 100px;
			width: $mobile-menu-width;

			.mobile-nav-links {
				list-style: none;
				margin-bottom: 20px;
				display: flex;
				align-items: center;
				cursor: pointer;
				font-size: 20px;

				a {
					color: $font-light-blue;
				}
			}
		}

		.mobile-menu-toggle {
			outline: none;
			border: none;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			cursor: pointer;
			position: fixed;
			top: 19px;
			left: 15px;
			width: 45px;
			height: 45px;
			border-radius: 50%;
			background-color: white;
		}
	}

	@media screen and (max-width: $laptop) {
		.navbar {
			font-size: 1rem;

			.nav-link {
				.au-nav {
					font-size: 1.6rem;
				}
			}
		}
	}
}
