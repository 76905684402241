.contact {
	height: 88vh;
	display: flex;
	flex-direction: column;
	background-color: $bg-light-blue;
	padding: 6% 10%;
	align-items: center;
	justify-content: space-evenly;

	.top-content {
		display: flex;

		.title {
			font-size: 11vmin;
			font-family: 'IBM Plex Serif';
		}

		.details {
			align-self: center;
			font-size: 20px;
			padding-left: 10%;
		}
	}

	.bottom-content {
		display: flex;
		padding-top: 10%;
		width: 100%;
		justify-content: space-between;

		a {
			font-size: 24px;
			color: $font-dark-blue;
			display: inline-flex;

			svg {
				width: 7vmin;
				height: 7vmin;
			}

			span {
				padding-left: 1vmin;
				align-self: center;
				width: 100%;
				white-space: nowrap;
			}
		}
	}

	@media screen and (max-width: $laptop) {
		.top-content {
			flex-direction: column;

			.details {
				padding-left: unset;
				padding-top: 10%;
			}
		}

		.bottom-content {
			flex-direction: column;
			padding-top: unset;

			a {
				padding-bottom: 10px;

				span {
					padding-left: 3vmin;
				}
			}
		}
	}

	@media screen and (max-width: $tablet) {
		justify-content: center;
		padding: 6% 35px;

		.details {
			padding-bottom: 10%;
		}
	}

	@media screen and (max-width: $mobile) {
		.top-content {
			.details {
				font-size: 16px;	
			}
		}

		.bottom-content {
			a {
				font-size: 16px;
			}
		}
	}
}
