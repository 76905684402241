/*-------- Variables --------*/
@import 'variables/fonts.scss';
@import 'variables/colors.scss';
@import 'variables/media.scss';
@import 'variables/layouts.scss';

/*-------- Components --------*/
@import 'components/nav.scss';
@import 'components/home.scss';
@import 'components/photos.scss';
@import 'components/about.scss';
@import 'components/material.scss';
@import 'components/contact.scss';
@import 'components/footer.scss';
@import 'components/signIn.scss';

html,
body {
	margin: 0;
	padding: 0;
	width: 100vw;
	overflow-x: hidden;
	font-family: Arial, Helvetica, sans-serif;
}

html {
	scroll-behavior: smooth;
}

h1,
h2,
h3,
ul,
ol,
dl {
	padding: unset;
	margin: unset;
}

p {
	margin-bottom: 0;
	margin-top: 0;
}

.todochange {
	padding-top: 100px;
}
