/* Background colors */

$bg-blue: #5e8f9d;
$bg-light-blue: #dcf2f5;
$bg-dark-blue: #0a2938;

$bg-green-blue: #34565f;

$bg-green: #4a5c3a;
$bg-light-green: #e0edd3;

/* Font Colors */
$font-dark-blue: #0a2938;
$font-light-blue: #ebf4f7;
