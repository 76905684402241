.sign-in {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: $bg-light-blue;

	.alert {
		position: unset;
	}

	input {
		margin-bottom: 0.5rem;
	}

	button {
		margin-top: 10px;
	}
}
