.home {
	height: 88vh;
	display: flex;
	flex-direction: row;
	color: $font-dark-blue;

	.left-content {
		width: 50vw;
		background-color: $bg-light-blue;

		.contain {
			margin: 0 12%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;

			h1 {
				font-size: 8vmin;
				font-family: 'IBM Plex Serif';
			}

			p {
				width: 90%;
				font-size: 20px;
				padding: 10% 0;
			}

			.contact-button {
				width: 16vw;
				height: 10vh;
				border: 2px solid $bg-dark-blue;
				font-size: 20px;
			}
		}
	}

	.right-content {
		width: 50vw;

		.img-wrapper {
			height: 100%;

			img {
				min-height: 100%;
				min-width: 100%;
				width: 100%;
				object-fit: cover;
				height: 100%;
			}
		}
	}

	@media screen and (max-width: $laptop) {
		.left-content {
			.contain {
				h1 {
					font-size: 8vmin;
				}

				.contact-button {
					height: 7vh;
					font-size: 18px;
				}
			}
		}
	}

	@media screen and (max-width: $tablet) {
		flex-direction: column;
		height: 120vh;

		.left-content {
			width: 100%;
			height: 50%;

			.contain {
				padding-bottom: 5vw;
				padding-top: 10vw;
				justify-content: space-evenly;

				p {
					font-size: 18px;
					padding: unset;
				}

				.contact-button {
					height: 50px;
					width: 150px;
					border-width: 1px;
				}
			}
		}

		.right-content {
			width: 100%;
			height: 50%;

			.img-wrapper {
				img {
					object-position: top;
				}
			}
		}
	}

	@media screen and (max-width: $mobile) {
		height: 100%;

		.left-content {
			height: 65vh;

			.contain {
				justify-content: center;
				padding: unset;

				h1 {
					font-size: 11vmin;
				}

				p {
					font-size: 14px;
					padding-bottom: 5vh;
					padding-top: 2vh;
				}
			}
		}

		.right-content {
			width: 100%;
			height: 120vw;

			.img-wrapper {
				img {
					object-position: unset;
					width: 100vw;
					height: 100%;
				}
			}
		}
	}
}

.page-break {
	height: 30vh;
	background-color: $bg-green;
	display: flex;
	flex-direction: column;
	color: white;
	align-items: center;
	justify-content: center;
	padding: 0 5%;

	hr {
		border-top: 3px solid white;
		color: white;
		width: 10vw;
	}

	.break-content {
		width: 50vw;
		font-size: 24px;
		padding-bottom: 24px;
		text-align: center;
	}

	@media screen and (max-width: $laptop) {
		.break-content {
			font-size: 20px;
		}
	}

	@media screen and (max-width: $tablet) {
		hr {
			width: 10vw;
		}

		.break-content {
			font-size: 18px;
			width: 60vw;
		}
	}

	@media screen and (max-width: $mobile) {
		height: unset;
		padding: 10% 35px;

		hr {
			margin-bottom: unset;
			width: 20%;
			border-top: 1px solid white;
		}

		.break-content {
			font-size: 14px;
			width: 100%;
			text-align: center;
			padding-bottom: unset;
		}
	}
}
