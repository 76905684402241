.about {
	.content-about {
		padding-bottom: 2rem;
	}

	@media screen and (max-width: $tablet) {
		.content-about {
			font-size: 14px;
		}
	}

	@media screen and (max-width: $mobile) {
		height: 100%;

		.left-content {
			padding-bottom: 0;
			height: 40vh;

			img {
				object-position: top;
			}
		}
	}
}
