.material {
	display: flex;
	flex-direction: column;
	padding: 5% 5% 0;

	hr {
		border: 1px solid $bg-dark-blue;
		width: 100%;
		margin: unset;
	}

	.title {
		font-family: 'IBM Plex Serif';
		font-size: 5vmin;
		padding-bottom: 3%;
	}

	.material-links {
		display: grid;
		grid-template-columns: auto auto auto;
		grid-column-gap: 5%;
		padding-bottom: 3%;

		.supplier {
			padding-bottom: 3%;

			p {
				font-size: 1.25rem;
				font-weight: bold;
			}

			a {
				text-decoration: none;
				color: $font-dark-blue;
				display: block;
			}
		}
	}

	@media screen and (max-width: $tablet) {
		.material-links {
			grid-template-columns: auto auto;
		}
	}

	@media screen and (max-width: $mobile) {
		padding: 50px 35px 0;

		.title {
			padding-bottom: 10%;
			font-size: 1.25rem;
		}

		.material-links {
			grid-template-columns: auto;
			padding-bottom: 50px;

			.supplier {
				padding-bottom: 5%;

				&:last-child {
					padding-bottom: unset;
				}

				p {
					font-size: 0.75rem;
				}

				a {
					font-size: 0.75rem;
				}
			}
		}
	}
}
