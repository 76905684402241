.photos {
	display: flex;
	flex-direction: column;
	padding: 5%;
	// background-color: $bg-light-blue;

	.more-photos-button {
		width: 16vw;
		height: 8vh;
		align-self: center;
		border: 2px solid $bg-dark-blue;
		color: $font-dark-blue;
		font-size: 20px;
	}

	@media screen and (max-width: $laptop) {
		.more-photos-button {
			height: 6vh;
			width: 16vw;
			font-size: 18px;
		}
	}

	@media screen and (max-width: $tablet) {
		padding: 50px 35px;

		.more-photos-button {
			height: 50px;
			width: 150px;
		}
	}

	@media screen and (max-width: $tablet) {
		.more-photos-button {
			border-width: 1px;
		}
	}
}

.img-grid {
	width: 100%;
	margin-bottom: 5%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 1vw;

	.img-wrap {
		overflow: hidden;
		height: 0;
		padding: 50% 0;
		position: relative;

		img {
			min-width: 100%;
			min-height: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	@media screen and (max-width: $tablet) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 2vw;
		z-index: -1;
	}

	@media screen and (max-width: $mobile) {
		grid-template-columns: auto;
		margin-bottom: 10%;
	}
}

.modal-bg {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	text-align: center;

	img {
		display: block;
		max-width: 60%;
		max-height: 80%;
		box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
		margin: 60px auto;
		border: 3px solid white;
	}
}
