.footer {
	background-color: $bg-green;
	padding: 2% 5%;

	.contain {
		display: flex;
		justify-content: space-evenly;

		ul {
			text-decoration: none;

			li {
				padding-bottom: 20px;
				list-style-type: none;

				&:first-child {
					font-size: 20px;
					text-transform: uppercase;
				}

				a {
					color: white;

					.icon {
						width: 48px;
						height: 48px;
					}
				}

				.mobile-email,
				.mobile-phone {
					display: none;
				}
			}
		}
	}

	.mobile-footer {
		display: none;
	}

	@media screen and (max-width: $laptop) {
		padding: 5%;
	}

	@media screen and (max-width: $laptop) {
		.contain {
			ul {
				li {
					font-size: 12px;

					&:first-child {
						font-size: 16px;
					}
				}
			}
		}
	}

	@media screen and (max-width: $mobile) {
		padding: 35px;

		.contain {
			display: none;
		}

		.mobile-footer {
			display: block;

			.footer-category {
				padding-bottom: 10%;

				.footer-title {
					display: flex;
					align-items: center;
					justify-content: space-between;

					svg {
						color: white;
						stroke-width: 3;
					}

					a,
					span {
						text-transform: uppercase;
						color: white;
						font-weight: bold;
					}
				}

				ul {
					list-style-type: none;
					padding-top: 5px;

					li {
						padding-bottom: 5px;
					}

					span,
					a {
						color: white;
					}
				}
			}
		}
	}
}
